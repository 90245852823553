<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>
                    {{ this.title }}
                </CCardHeader>
                <vue-confirm-dialog></vue-confirm-dialog>

                <CCardBody>
                    <div>
                        <CRow>
                            <CCol sm="4">
                                <CInput
                                        id="searchName"
                                        label="名前"
                                        type="text"
                                        horizontal
                                        v-model="name"
                                />
                            </CCol>
                        </CRow>
                        <CRow class="mb-3">
                            <CCol sm="10">
                                <CButton color="primary" class="mr-2" @click="filterData">検索</CButton>
                                <CButton color="danger" @click="resetFilterData">取り消す</CButton>
                            </CCol>
                        </CRow>
                    </div>
                    <CRow class="text-right mb-2">
                        <CCol sm="12">
                            <CButton color="primary" class="mr-2" @click="renderAddItem">追加</CButton>
                        </CCol>
                    </CRow>

                    <div v-if="loading">
                        <LoadingCompoment />
                    </div>
                    <div v-else :key="key">
                        <DataTable
                                hover
                                striped
                                border
                                :items="data"
                                :fields="fields"
                                :items-per-page="perPage"
                                :active-page="page"
                                :pagination-data="paginationData"
                                @page-change="pageChange"
                                @pagination-change="selectChangePerpage"
                                :pagination="{ doubleArrows: false, align: 'center'}"
                                :items-per-page-select="{
                  label: '表示件数',
                  values: [5, 10, 20, 30, 50, 100],
              }"
                        >
                            <div slot="select-header">
                                <CInputCheckbox
                                        label=""
                                        class="pb-3"
                                        name="listSelected"
                                        @update:checked="() => handleCheckAll()"
                                        :checked="checkAll"
                                />
                            </div>
                            <td slot="select" slot-scope="{item}">
                                <div>
                                    <CInputCheckbox
                                            :key="item.id"
                                            label=""
                                            :value="item.item"
                                            name="listSelected"
                                            @update:checked="() => handleSelect(item)"
                                            :checked="listSelected.includes(item.id)"
                                    />
                                </div>
                            </td>
                            <td slot="id" slot-scope="{item}">
                                <div> {{ item.id }}</div>
                            </td>
                            <td slot="name" slot-scope="{item}">
                                <div v-if="isColorsPage">
                                    {{ item.color_code }} : {{ item.name }}
                                </div>
                                <div v-else>{{ item.name }}</div>
                            </td>
                          <td slot="address" slot-scope="{item}">
                            <div> {{ item.address || ''}}</div>
                          </td>
                          <td slot="tel" slot-scope="{item}">
                            <div> {{ item.tel || ''}}</div>
                          </td>
                          <td slot="zipcode" slot-scope="{item}">
                            <div> {{ item.zipcode || ''}}</div>
                          </td>
                          <td slot="rate" slot-scope="{item}">
                            <div> {{ item.rate }}%</div>
                          </td>
                            <td slot="created_at" slot-scope="{item}">
                                <div>{{item.created_at || ''}}</div>
                            </td>
                            <td slot="action" slot-scope="{item}" class="text-center" style="width: 100px">
                                <div>
                                    <CButton @click="() => renderDetail(item)" ><CIcon name="cil-pencil" /></CButton>
<!--                                    <CButton @click="() => handleConfirmDelete(item)" ><CIcon name="cil-recycle" /></CButton>-->
                                </div>
                            </td>
                        </DataTable>
                    </div>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import ResourceApi from '../../api/resourceApi';
    import DataTable from "@/components/DataTable";
    import Vue from "vue";

    export default {
        name: 'ListBasicComponent',
        components: {DataTable},
        props: {
            endPoint: String,
            title: String,
            field: Array,
        },
        data () {
            return {
                loading: false,
                checkAll: false,
                listSelected: [],
                paginationData: {},
                page: 1,
                perPage: 30,
                query: {page: this.page, per_page: this.perPage, type_data: this.typeDataSelected},
                key: Math.random(),
                optionsPerPage: [10, 20, 30, 40, 50, 100],
                fields:  this.field,
                activePage: 1,
                data: [],
                name: '',
                isColorsPage: this.endPoint === 'colors'
            }
        },
        watch: {
            listSelected: function(newVal, oldVal) {
                let checkAll = true;
                this.data.map(s => {
                    if(!newVal.includes(s.id)){
                        checkAll = false;
                    }
                })
                this.checkAll = checkAll;
            },
            items: function(newVal, oldVal) {
                let checkAll = true;
                this.data.map(s => {
                    if(!this.listSelected.includes(s.id)){
                        checkAll = false;
                    }
                })
                this.checkAll = checkAll;
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData (params) {
                // this.resetSelected()
                this.loading = true
                this.key =  Math.random()
                this.query ={...this.query, ...params }
                const api = new ResourceApi(this.endPoint)
                api.index(this.query).then(response => {
                    // console.log(response.data)
                    this.data = response.data.data;
                    this.paginationData = response.data;
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                })
            },

            handleCheckAll() {
                let listSelected = this.listSelected
                if(this.checkAll){
                    this.data.map(s => {
                        listSelected = listSelected.filter(l => s.id !== l)
                    })
                }else {
                    this.data.map(s => {
                        listSelected.push(s.id)
                    })
                }
                this.listSelected = listSelected;
            },
            handleSelect(item){
                if(this.listSelected.includes(item.id)){
                    this.listSelected = this.listSelected.filter(s => s !== item.id)
                }else {
                    this.listSelected.push(item.id)
                }
            },
            filterData () {
                this.page = 1;
                let params = this.getParams({page: 1});
                this.getData(params);
            },

            getParams(param){
                let params = {
                    name_like: this.name,
                }
                return  {...params, ...param}
            },

            handleConfirmDelete(item){
                let message = '削除しますか？'
                this.$confirm(
                    {
                        title: '確認',
                        message: message,
                        button: {
                            no: 'いいえ',
                            yes: 'はい'
                        },
                        callback: confirm => {
                            if (confirm) {
                                // ... do something
                                this.handleDeleteItem(item)
                            }
                        }
                    }
                )
            },

            handleDeleteItem(item){
                const api = new ResourceApi(this.endPoint)
                api.delete(item).then(response => {
                    Vue.$toast.success('削除に成功しました。');
                    this.getData();
                }).catch(error => {
                    // Vue.$toast.error('Error')
                }).finally(() =>{
                    this.loading = false
                })
            },

            resetFilterData () {
                this.name = '';
                let params = { name_like:  '' }
                this.getData(params);
            },

            selectChangePerpage (val) {
                this.perPage =  val  ? parseInt(val) : ''
                this.getData({per_page: val});
            },

            pageChange (val) {
                this.page = val;
                this.getData({page: val});
                // this.checkAll = false;
                // this.$router.push({ query: { page: val }})
            },
            renderDetail(item) {
                this.$router.push({path: `${this.endPoint}/${item.id}`})
            }   ,
            renderAddItem() {
                this.$router.push({path: `${this.endPoint}/create`})
            }
        }
    }
</script>
