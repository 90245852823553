<template>
    <ListBasicComponent end-point="major-categories" title="大分類" :field="fields"></ListBasicComponent>
</template>

<script>

    import ListBasicComponent from "@/components/views/ListBasicComponent";
    export default {
        name: 'MajorCategories',
        components: {ListBasicComponent},
        data() {
            return {
                fields: [
                    { key: 'id', label: 'ID'},
                    { key: 'name', label: '名前' },
                    { key: 'action', label: '' },
                ]
            }
        }
    }
</script>
